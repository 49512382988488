import { EntityStatus } from "@prisma/client"
import { z } from "zod"
import {
  AVERAGE_TENURE_OPTIONS,
  EDUCATION_LEVELS,
  PEOPLE_HIGHLIGHTS,
  SENIORITY_LEVELS,
} from "~/consts/signals"
import {
  companyOperatorsFilterSchema,
  currentTenureFilterSchema,
  getInnerSchemaAndOptions,
  languagesFilterSchema,
  numericFilterRevampedSchema,
  NUMERIC_OPERATORS,
  setFilterSchema,
  textFilterSchema,
} from "~/utils/signal"

export type PeopleDBFilters = z.infer<typeof peopleFiltersValidation>

const peopleOverviewFiltersSchema = (isTalent?: boolean) =>
  z
    .object({
      ids: z.array(z.string()),
      Location: z.array(z.string()),
      Highlights: setFilterSchema(z.enum(PEOPLE_HIGHLIGHTS)),
      Skills: setFilterSchema(),
      Languages: languagesFilterSchema,
      Tagline: z.array(z.string()),
      ...(isTalent && {
        TalentTags: textFilterSchema,
      }),
    })
    .partial()

const peopleExperienceFiltersSchema = z
  .object({
    JobTitles: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("JobTitles")
    ),
    PastJobTitles: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("PastJobTitles")
    ),
    Department: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("Department")
    ),
    YearsOfExperience: numericFilterRevampedSchema(),
    SeniorityLevel: z.array(z.enum(SENIORITY_LEVELS)),
    CurrentTenure: currentTenureFilterSchema,
    AverageTenure: z.enum(AVERAGE_TENURE_OPTIONS),
    HasCurrentPosition: z.boolean(),
  })
  .partial()

const peopleCompanyOperatorFiltersSchema = (isTalent?: boolean) =>
  z
    .object({
      Companies: companyOperatorsFilterSchema(
        getInnerSchemaAndOptions("Companies")
      ),
      CompanyName: companyOperatorsFilterSchema(
        getInnerSchemaAndOptions("CompanyName")
      ),
      CompanyHQLocation: companyOperatorsFilterSchema(
        getInnerSchemaAndOptions("CompanyHQLocation")
      ),
      CompanyIndustries: companyOperatorsFilterSchema(
        getInnerSchemaAndOptions("CompanyIndustries")
      ),
      CompanyGrowthStage: companyOperatorsFilterSchema(
        getInnerSchemaAndOptions("CompanyGrowthStage")
      ),
      CompanySize: companyOperatorsFilterSchema(
        getInnerSchemaAndOptions("CompanySize")
      ),
      CompanyTotalFundingAmount: companyOperatorsFilterSchema(
        getInnerSchemaAndOptions("CompanyTotalFundingAmount")
      ),
      CompanyFundingType: companyOperatorsFilterSchema(
        getInnerSchemaAndOptions("CompanyFundingType")
      ),
      CompanyLastFundingAmount: companyOperatorsFilterSchema(
        getInnerSchemaAndOptions("CompanyLastFundingAmount")
      ),
      CompanyInvestors: companyOperatorsFilterSchema(
        getInnerSchemaAndOptions("CompanyInvestors")
      ),
      CompanyFoundedDate: companyOperatorsFilterSchema(
        getInnerSchemaAndOptions("CompanyFoundedDate")
      ),
      ...(isTalent && {
        NewCompanyTagline: textFilterSchema,
      }),
    })
    .partial()

const peopleEducationFiltersSchema = z
  .object({
    Education: setFilterSchema(),
    EducationLocation: z.array(z.string()),
    EducationLevel: z.array(z.enum(EDUCATION_LEVELS)),
    FieldOfStudy: z.array(z.string()),
    GraduationYear: numericFilterRevampedSchema({
      defaultValue: NUMERIC_OPERATORS.GTE,
      extraConstraints: (z) => z.lte(new Date().getFullYear()),
    }),
  })
  .partial()

const peopleSocialFiltersSchema = z
  .object({
    Connections: numericFilterRevampedSchema(),
    Followers: numericFilterRevampedSchema(),
  })
  .partial()

export const peopleFiltersValidation = peopleOverviewFiltersSchema()
  .merge(peopleExperienceFiltersSchema)
  .merge(peopleCompanyOperatorFiltersSchema())
  .merge(peopleEducationFiltersSchema)
  .merge(peopleSocialFiltersSchema)
  .merge(
    z.object({
      entityStatus: z.nativeEnum(EntityStatus).optional(),
    })
  )

export const peopleAdvancedFiltersSchema = (isTalent?: boolean) =>
  z.object({
    General: z.object({
      General: peopleOverviewFiltersSchema(isTalent).omit({
        ids: true,
      }),
    }),
    Experience: z.object({
      Experience: peopleExperienceFiltersSchema,
    }),
    Companies: z.object({
      Companies: peopleCompanyOperatorFiltersSchema(isTalent),
    }),
    Education: z.object({ Education: peopleEducationFiltersSchema }),
    Social: z.object({ Social: peopleSocialFiltersSchema }),
  })
